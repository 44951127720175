<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
     <span class="brand-logo">
          <b-avatar
              size="40"
              variant="light-primary"
              :src="appLogoImage"
          />
          </span>
      <h2 class="text-primary mb-0 ml-1">
        {{ appName }}
      </h2>
    </div>


    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />

      <b-nav-item @click="connect">
        <div class="d-sm-flex d-none user-nav">
          <p class="text-primary font-weight-bolder mb-0">
           {{ walletOn ? 'Desconectar ' : 'Conectar '}}Wallet
          </p>
        </div>
      </b-nav-item>
      <b-nav-item @click="connect">
        <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/icons/wallet.png')"
            class="badge-minimal"
            :badge-variant="walletOn? 'success': 'danger'"
        />
      </b-nav-item>

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavItem, BImg, BButton, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {mapState} from 'vuex'
import { $themeConfig } from '@themeConfig'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed1.binance.org'
      },
      chainId: 56
    }
  }
}
export default {
  components: {
    BLink,
    BNavItem,
    BImg,
    BNavbarNav,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      walletOn: state => state.w3.active
    })
  },
  mounted() {
    this.$nextTick(async () => {
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
      })
      this.$store.commit('w3/setWeb3Modal', web3Modal)
      // if (web3Modal.cachedProvider) {
      //   await this.$store.dispatch('w3/connect')
      // }
    })
  },
  methods:{
    connect(){
      if(this.walletOn){
        this.$store.dispatch('w3/resetApp')
        return;
      }
      this.$store.dispatch('w3/connect')
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  }
}
</script>
